import { TabsEnum } from "../../../../enums/TabsEnum";

export interface IMyTabMenu {
  changeTab: (index: number) => any;
  selectedTab: number;
}
export const MyTabMenu = ({ changeTab, selectedTab }: IMyTabMenu) => {
  const onChangeTab = (index: number) => {
    changeTab(index);
  };
  return (
    <div className="mb-2">
      <div
        onClick={() => onChangeTab(TabsEnum.firstTab)}
        className={
          "my-time-panel-button " +
          (selectedTab == TabsEnum.firstTab
            ? "my-time-panel-button-active"
            : "")
        }
      >
        Шаг 1 - превью
      </div>
      <div
        onClick={() => onChangeTab(TabsEnum.secondTab)}
        className={
          "my-time-panel-button " +
          (selectedTab == TabsEnum.secondTab
            ? "my-time-panel-button-active"
            : "")
        }
      >
        Шаг 2 - подробная информация
      </div>
    </div>
  );
};
