import React, { FormEvent, SyntheticEvent } from "react";

export const setHelper = (
  setF: (oldVal: any) => any,
  e: React.ChangeEvent<any>,
) => {
  setF((oldVal: {}) => {
    return {
      ...oldVal,
      [e.target.name]: e.target.value,
    };
  });
};
export const getFormatedDate = (unformatedDate: string) => {
  const newDate = unformatedDate.split(".")[0].split("T").join(" ");
  return newDate;
};
