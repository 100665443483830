import { BasePage } from "../../common/BasePage";
import { CreateEventForm } from "./CreateEventForm";

export const CreateEventPage = () => {
  return (
    <BasePage>
      <CreateEventForm />
    </BasePage>
  );
};
