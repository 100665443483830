import { IEventElement } from "../../../interfaces/IEventElement";
import React from "react";
import { EventComponent } from "../../../types/EventComponent";
import { ListOfTags } from "../../common/ListOfTags";
import { serverUrl } from "../../../env";
import { QueryService } from "../../../services/QueryService";
import { getFormatedDate } from "../../../utils";

interface IEvent {
  key?: any;
  event: EventComponent;
  className: string;
}
export const EventComp = ({ event, className }: IEvent) => {
  return (
    <div className={className}>
      <div className="card">
        <div style={{ width: 100 + "%", height: 25 + "vh" }}>
          <img
            width={100 + "%"}
            height={100 + "%"}
            src={QueryService.getServerUri() + "/" + event.img_path}
            className="card-img-top"
            alt="..."
          />
        </div>
        <div className="card-body">
          <div>
            <span className="my-card-title">{event.title}</span>
          </div>
          <div>
            <span className="my-card-date">
              {getFormatedDate(event.event_date).substring(
                0,
                getFormatedDate(event.event_date).length - 3,
              )}
            </span>
            {/*<span className="my-card-date">*/}
            {/*  {getFormatedDate(event.event_date).split(" ")[1]}*/}
            {/*</span>*/}
          </div>
        </div>
        <div className="card-footer">
          <ListOfTags
            listOfTags={[
              {
                label: "Нужна запись",
              },
              {
                label: "500₽",
              },
            ]}
          />
        </div>
      </div>
    </div>
  );
};
