import { TgLink } from "../components/common/TgLink";
import reactStringReplace from "react-string-replace";

export class StringHelper {
  public static addTgLabel(text: string) {
    const res = text.split(" ").map((word) => {
      if (word[0] === "@") {
        return reactStringReplace("whats your name", "your", (match, i) => (
          <strong>{match}</strong>
        ));
      }
      return word;
    });
    console.log(res);
    return res.join(" ");
  }
}
