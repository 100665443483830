import React, { useEffect } from "react";
import "./style/App.scss";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { ListPage } from "./components/pages/list/ListPage";
import { useTelegram } from "./hooks/useTelegram";
import { OrgPage } from "./components/pages/org/OrgPage";
import { CreateEventPage } from "./components/pages/create/CreateEventPage";
import { InfoPage } from "./components/pages/about/InfoPage";
import { SingleEventPage } from "./components/pages/singeEvent/SingleEventPage";
import { EditEventPage } from "./components/pages/edit/EditEventPage";

// function App() {
//   return (
//     <div className="App">
//       <header className="App-header">
//         <img src={logo} className="App-logo" alt="logo" />
//         <p>
//           Edit <code>src/App.tsx</code> and save to reload.
//         </p>
//         <a
//           className="App-link"
//           href="https://reactjs.org"
//           target="_blank"
//           rel="noopener noreferrer"
//         >
//           Learn React
//         </a>
//       </header>
//     </div>
//   );
// }

const router = createBrowserRouter([
  {
    path: "/",
    element: <ListPage />,
  },
  {
    path: "/org",
    element: <OrgPage />,
  },
  {
    path: "/about",
    element: <ListPage />,
  },
  {
    path: "/create",
    element: <CreateEventPage />,
  },
  {
    path: "/info",
    element: <InfoPage />,
  },
  {
    path: "/events/:eventId",
    element: <SingleEventPage />,
  },
  {
    path: "/edit/:eventId",
    element: <EditEventPage />,
  },
]);

export const App = () => {
  const { tg } = useTelegram();
  useEffect(() => {
    if (tg) {
      tg.ready();
    }
  }, []);
  return (
    <div className="App">
      <RouterProvider router={router} />
    </div>
  );
};
