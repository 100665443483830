import { BasePage } from "../../common/BasePage";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { EventDrawView } from "./EventDrawView";
import { IMoveEvent } from "../../../interfaces/IMoveEvent";

const listOfEventsNames = [
  "Киноклуб",
  "Книжный клуб",
  "Открытая тренировка",
  "Настольные игры",
];
function getRandomArbitrary(min: number, max: number) {
  return Math.floor(Math.random() * (max - min) + min);
}

const parentContainerWidth = 300;
const parentContainerHeight = 300;
const diffX = 2;
const diffY = 2;
const destinationX = 150;
const destinationY = 150;

export const InfoPage = observer(() => {
  const [listOfViews, setListOfViews]: [IMoveEvent[], Function] = useState([]);
  const handelClick = () => {
    const randomEventName = getRandomArbitrary(0, listOfEventsNames.length);
    setListOfViews((prev: string[]) => [
      ...prev,
      {
        name: listOfEventsNames[randomEventName],
        top: 0,
        left: (parentContainerWidth * getRandomArbitrary(0, 100)) / 100,

        // top: destinationX,
        // left: destinationX,
      },
    ]);
  };
  useEffect(() => {
    const timerId = setInterval(() => {
      console.log("Работает");
      listOfViews.forEach((el) => {
        el.top += diffY;
        el.left += diffX * +(el.left < destinationX);
      });
      setListOfViews((prev: any) => [
        ...listOfViews.filter((el) => el.top < parentContainerHeight),
      ]);
    }, 300);
    return () => {
      clearInterval(timerId);
    };
  });
  return (
    <BasePage>
      <div
        style={{
          position: "relative",
          border: 1 + "px solid black",
          width: parentContainerWidth,
          height: parentContainerHeight,
        }}
      >
        <button onClick={handelClick} className="btn btn-info">
          Click
        </button>
        {listOfViews.map((el, index) => {
          return <EventDrawView key={index} el={el} />;
        })}
      </div>
    </BasePage>
  );
});
