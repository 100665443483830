import React from "react";

export interface IListOfTags {
  listOfTags: { label: string }[];
}
export const ListOfTags = ({ listOfTags }: IListOfTags) => {
  return (
    <div className="d-flex justify-content-start">
      {listOfTags.map((el) => {
        return (
          <div
            key={el.label}
            className="card-button-panel-need-reg-button me-1"
          >
            {el.label}
          </div>
        );
      })}
    </div>
  );
};
