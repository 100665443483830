import { observer } from "mobx-react-lite";
import { useContext, useState } from "react";
import { storeContext } from "../../../store/root-store";
import { DateButtons } from "../../../enums/DateButtons";

export const DatePanel = observer(() => {
  const { eventsStore, messagesStoreGl, settingsGl } = useContext(storeContext);

  const [currentButton, setCurrentButton]: [string, Function] = useState(
    DateButtons.all,
  );

  const dateFilter = async (start: number, finish: number) => {
    eventsStore.listOfPreviewEvents = [];
    settingsGl.dateFromSec = start;
    settingsGl.dateToSec = finish;
    settingsGl.currentPage = 0;
    eventsStore.listOfPreviewEventsLoaded = false;
  };

  const onTodayButtonClick = () => {
    setCurrentButton(DateButtons.today);
    const todaySec = Math.floor(Date.now() / 1000);
    dateFilter(todaySec, todaySec);
  };
  const onTomorrowButtonClick = () => {
    setCurrentButton(DateButtons.tomorrow);

    const tomorrowSec = Math.floor(Date.now() / 1000) + 24 * 60 * 60;
    dateFilter(tomorrowSec, tomorrowSec);
  };
  const onWeekButtonClick = () => {
    const getEndOfTheWeek = (dateObj: Date) => {
      let day = dateObj.getDay();
      let diff = 7 - day;
      let neDate = new Date();
      return Math.floor(
        new Date(neDate.setDate(dateObj.getDate() + diff)).getTime() / 1000,
      );
    };

    setCurrentButton(DateButtons.week);

    const date = new Date();
    const startDiff =
      date.getDate() - date.getDay() + (date.getDay() === 0 ? -6 : 1);
    const endDiff =
      date.getDate() + date.getDay() + (date.getDay() === 0 ? -6 : 1);

    // Set the date to the start of the week by setting it to the calculated difference
    const startOfTheWeek = new Date(date.setDate(startDiff));
    const endOfTheWeek = new Date(date.setDate(endDiff));
    // endOfTheWeek.toUTCString()
    const startOfTheWeekSec = Math.floor(startOfTheWeek.getTime() / 1000);
    const endOfTheWeekSec = getEndOfTheWeek(date);

    dateFilter(startOfTheWeekSec, endOfTheWeekSec);
  };

  const onAllButtonClick = () => {
    setCurrentButton(DateButtons.all);
    dateFilter(0, 0);
  };

  return (
    <div className="mt-2">
      <div className="d-flex">
        <div
          onClick={onTodayButtonClick}
          className={
            "my-time-panel-button me-1  " +
            (currentButton == DateButtons.today
              ? " my-time-panel-button-active"
              : "")
          }
        >
          Сегодня
        </div>
        <div
          className={
            "my-time-panel-button me-1" +
            (currentButton == DateButtons.tomorrow
              ? " my-time-panel-button-active"
              : "")
          }
          onClick={onTomorrowButtonClick}
        >
          Завтра
        </div>
        <div
          className={
            "my-time-panel-button me-1" +
            (currentButton == DateButtons.week
              ? " my-time-panel-button-active"
              : "")
          }
          onClick={onWeekButtonClick}
        >
          Неделя
        </div>
        <div
          className={
            "my-time-panel-button me-1" +
            (currentButton == DateButtons.all
              ? " my-time-panel-button-active"
              : "")
          }
          onClick={onAllButtonClick}
        >
          Все
        </div>
      </div>
      <div></div>
    </div>
  );
});
