export const Header = () => {
  return (
    <div className="d-flex my-header">
      <div>
        <img
          width={70 + "px"}
          height={"auto"}
          src="/Group 30MyLogo.svg"
          alt=""
        />
      </div>
      <div className="my-header-city">Краснодар</div>
    </div>
  );
};
