import { setHelper } from "../../../../utils";
import { IFullEvent } from "../../../../interfaces/IFullEvent";
import { IFormEventType } from "../../../../types/FormEventType";

export interface ISecondTabPreviewInfo {
  event: IFormEventType;
  setEvent: (oldVal: any) => any;
}
export const SecondTabFullInfo = ({
  event,
  setEvent,
}: ISecondTabPreviewInfo) => {
  return (
    <div>
      <div className="mt-2">
        <label htmlFor="event_desc" className="form-label">
          Подробное описание
        </label>
        <textarea
          onChange={(e) => setHelper(setEvent, e)}
          className="form-control"
          placeholder="Самое подробное описание мероприятия. О чем оно? Кому писать для записи? "
          name="description"
          value={event.description}
          id="event_desc"
          required
          cols={30}
          rows={12}
        ></textarea>
      </div>
    </div>
  );
};
