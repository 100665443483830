import { getFormatedDate, setHelper } from "../../../../utils";
import { IFormEventType } from "../../../../types/FormEventType";

export interface IFirstTabPreviewInfo {
  event: IFormEventType;
  setEvent: (oldVal: any) => any;
}
export const FirstTabPreviewInfo = ({
  event,
  setEvent,
}: IFirstTabPreviewInfo) => {
  return (
    <div>
      <div className="">
        <div className="">
          <label htmlFor="event_title" className="form-label">
            Название события
          </label>
          <input
            onChange={(e) => setHelper(setEvent, e)}
            id="event_title"
            name="title"
            type="text"
            required
            value={event.title ?? ""}
            className="form-control"
            placeholder="Название"
          />
        </div>
        <div className="mt-2">
          <label htmlFor="event_date" className="form-label">
            Дата и время события
          </label>
          <input
            id="event_date"
            onChange={(e) => setHelper(setEvent, e)}
            value={event.event_date ? getFormatedDate(event.event_date) : ""}
            name="event_date"
            className="form-control"
            aria-label="Date and time"
            required
            type="datetime-local"
          />
        </div>
        <div className="mt-2">
          <label htmlFor="event_date" className="form-label">
            Место проведения
          </label>
          <input
            placeholder="Укажите место, без города"
            id="location"
            onChange={(e) => setHelper(setEvent, e)}
            value={event.location}
            name="location"
            className="form-control"
            aria-label="Адрес"
            required
            type="text"
          />
        </div>
        <div className="mt-2">
          <label htmlFor="event_desc" className="form-label">
            Цена
          </label>
          <input
            type="number"
            name="price"
            onChange={(e) => setHelper(setEvent, e)}
            value={event.price}
            required
            min={0}
            className="form-control"
            placeholder="0 - бесплатно"
            step={0.01}
          />
        </div>
      </div>
    </div>
  );
};
