import { useState } from "react";

export interface IFilePicker {
  setEvent: (oldVal: any) => any;
}
export const FilePicker = ({ setEvent }: IFilePicker) => {
  const [selectedFile, setSelectedFile] = useState("");
  const handleFileSelect = (event: any) => {
    setSelectedFile(event.target.files[0]);
    setEvent((prev: any) => {
      return {
        ...prev,
        main_img: event.target.files[0],
      };
    });
  };
  return (
    <div className="mt-2">
      <label htmlFor="event_img" className="form-label">
        Превью фото
      </label>
      <input
        onChange={handleFileSelect}
        id="event_img"
        name="event_img"
        required
        type="file"
        className="form-control"
        placeholder="Картинка"
      />
    </div>
  );
};
