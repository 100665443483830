import { observer } from "mobx-react-lite";
import { EditEventTabsIndex } from "./tabs/EditEventTabsIndex";
import { IFormEventType } from "../../../types/FormEventType";
export const CreateEventForm = observer(() => {
  return (
    <div>
      <EditEventTabsIndex
        formSendUri="/api/events/saveEvent"
        showFilePicker={true}
        formData={{} as IFormEventType}
      />
    </div>
  );
});
