// @ts-ignore
import { Telegram } from "@tg-app/types";

// @ts-ignore
export const tg: Telegram.WebApp = window.Telegram.WebApp;
export function useTelegram() {
  const onClose = () => {
    tg.close();
  };
  const onToggleButton = () => {
    if (tg.MainButton.isVisible) {
      tg.MainButton.hide();
    } else {
      tg.MainButton.show();
    }
  };
  const showTgButton = () => {
    tg.MainButton.show();
  };
  return {
    tg,
    onClose,
    onToggleButton,
    showTgButton,
    user: tg?.initDataUnsafe?.user,
  };
}
