import { BasePage } from "../../common/BasePage";
import { useParams } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { CreateEventForm } from "../create/CreateEventForm";
import { storeContext } from "../../../store/root-store";
import { IFullEvent } from "../../../interfaces/IFullEvent";
import { Loader } from "../../common/Loader";
import { EditEventTabsIndex } from "../create/tabs/EditEventTabsIndex";
import { observer } from "mobx-react-lite";

export const EditEventPage = observer(() => {
  const { eventId } = useParams();
  const { singleStoreGL } = useContext(storeContext);
  const [isLoad, setIsLoad] = useState(false);

  const loadInit = async () => {
    setIsLoad(false);
    singleStoreGL.fullEvent = {} as IFullEvent;
    if (eventId) {
      await singleStoreGL.loadSingleEvent(+eventId);
      setIsLoad(true);
    }
  };
  useEffect(() => {
    loadInit();
  }, []);

  return (
    <BasePage>
      {isLoad && singleStoreGL.fullEvent.event_date.replace("T", " ")}
      {isLoad ? (
        <EditEventTabsIndex
          formData={singleStoreGL.fullEvent}
          showFilePicker={false}
          formSendUri="/api/events/updateEvent"
        />
      ) : (
        <Loader />
      )}
    </BasePage>
  );
});
