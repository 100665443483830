import React, { ReactNode, useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { Loader } from "./Loader";

export interface IMyCustomScroll {
  children?: ReactNode;
  loadData: (currentPage: number) => Promise<{
    data: any[];
    hasMore: boolean;
  }>;
}
export const MyCustomScroll = ({ children, loadData }: IMyCustomScroll) => {
  const [items, setItems]: [any[], Function] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [index, setIndex] = useState(1);
  const [isLoad, setIsLoad] = useState(false);

  const loadDadaWrapper = async (page: number = 0) => {
    setIsLoad(false);
    const res = await loadData(page);
    setHasMore(res.hasMore);
    setIsLoad(true);
  };

  useEffect(() => {
    loadDadaWrapper(0);
    // loadItems();
    // fetchMoreData(0);
    // loadDadaWrapper();
  }, []);

  const fetchMoreData = async (currentIndex = index) => {
    const res = await loadData(currentIndex);
    setItems((prevItems: any) => [...prevItems, ...res.data]);
    setHasMore(res.hasMore);
    setIndex((prevIndex) => prevIndex + 1);
  };

  return (
    <InfiniteScroll
      dataLength={items.length}
      next={fetchMoreData}
      hasMore={hasMore}
      loader={<Loader />}
    >
      {children}
    </InfiniteScroll>
  );
};
