import { makeAutoObservable } from "mobx";

export class Settings {
  public dateFromSec: number = 0;
  public dateToSec: number = 0;
  public currentPage: number = 0;
  public chunkLength: number = 4;
  public hasMore: boolean = true;
  constructor() {
    makeAutoObservable(this);
  }
}
export const settingsGl = new Settings();
