import { makeAutoObservable } from "mobx";
import { MessageTypes } from "../enums/MessageTypes";
import { IMessage } from "../interfaces/IMessage";

export class Messages {
  public data: Message[] = [];
  constructor() {
    makeAutoObservable(this);
  }
  add(message: IMessage) {
    const newMessage = new Message(message);
    this.data.push(newMessage);
    const timeoutId = setTimeout(() => {
      this.data = this.data.filter((el) => el !== newMessage);
    }, message.durationMs);
    newMessage.timeoutId = timeoutId;
    newMessage.lastMs = Date.now() + message.durationMs;
    return {
      message: newMessage,
      timeoutId,
    };
  }
  deleteMessage(message: Message) {
    clearTimeout(message.timeoutId);
    this.data = this.data.filter((el) => el != message);
  }
  startInterval() {
    const intervalId = setInterval(() => {
      this.data.forEach((el, index) => {
        // if (index % 2 === 0) {
        el.text = "" + (el.lastMs - Date.now());
        // }
      });
    }, 100);
  }
}
export class Message implements IMessage {
  text!: string;
  type!: MessageTypes;
  durationMs!: number;
  lastMs!: number;
  public timeoutId!: NodeJS.Timeout;
  constructor(message: IMessage) {
    makeAutoObservable(this);
    this.text = message.text;
    this.durationMs = message.durationMs;
    this.type = message.type;
  }
}
export const messagesStore = new Messages();
