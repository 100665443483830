import { makeAutoObservable } from "mobx";
import { QueryService } from "../services/QueryService";
import { IFullEvent } from "../interfaces/IFullEvent";

export class SingleEvent {
  public fullEvent: IFullEvent = {} as IFullEvent;
  constructor() {
    makeAutoObservable(this);
  }

  /**
   * Получить полную информацию по конкретному событию
   * @param eventId
   */
  public async loadSingleEvent(eventId: number) {
    const res = await QueryService.getQuery("/api/events/getById/" + eventId);
    console.log("single create");
    console.log(res);
    this.fullEvent = res?.data.data.result[0];
  }
}
export const singleEvent = new SingleEvent();
