import { observer } from "mobx-react-lite";
import { CSSProperties, useContext, useEffect, useState } from "react";
import { storeContext } from "../../store/root-store";
import { MessageTypes } from "../../enums/MessageTypes";
import { Message, messagesStore } from "../../store/Messages";

export const MessageArea = observer(() => {
  const { messagesStoreGl } = useContext(storeContext);

  console.log("Обновляю компонент");
  const createMessage = () => {
    messagesStore.add({
      text: "Успешно сохранили!",
      type: MessageTypes.ok,
      durationMs: 30000,
    });
  };
  const onDelete = (message: Message) => {
    messagesStoreGl.deleteMessage(message);
  };
  return (
    <div>
      {messagesStoreGl.data.map((el) => {
        return <MessageLoc message={el} delCallback={onDelete} />;
      })}
    </div>
  );
});
const MessageLoc = observer(
  ({ message, delCallback }: { message: Message; delCallback: Function }) => {
    const [messageStyle, setMessageStyle]: [CSSProperties, Function] = useState(
      {
        position: "absolute",
        width: 90 + "%",
        marginLeft: "auto",
        marginRight: "auto",
        top: 20 + "px",
        left: 0,
        right: 0,
        textAlign: "center",
        zIndex: 100,
      },
    );
    const onClick = () => {
      delCallback(message);
    };
    return (
      <div style={{ position: "relative" }}>
        <div
          style={messageStyle}
          className={
            "d-flex justify-content-between " +
            (message.type === MessageTypes.ok
              ? "alert alert-success"
              : "alert alert-danger")
          }
        >
          <span>{message.text}</span>

          <button onClick={onClick} className="btn my-event-button">
            Ок
          </button>
        </div>
      </div>
    );
  },
);
