import { createContext } from "react";
import { eventsStore } from "./Events";
import { messagesStore } from "./Messages";
import { settingsGl } from "./Settings";
import { singleEvent } from "./SingleEvent";

export const storeContext = createContext({
  eventsStore: eventsStore,
  messagesStoreGl: messagesStore,
  settingsGl: settingsGl,
  singleStoreGL: singleEvent,
});
