import { Header } from "./Header";
import { Footer } from "./Footer";
import { MessageArea } from "./MessageArea";

export interface IBasePage {
  children: any;
}
export const BasePage = ({ children }: IBasePage) => {
  return (
    <div>
      <div className="container-fluid">
        <Header />
        <MessageArea />
        {children}
        <Footer />
      </div>
    </div>
  );
};
