import { IMoveEvent } from "../../../interfaces/IMoveEvent";

export interface IEventDrawView {
  el: IMoveEvent;
}
export const EventDrawView = ({ el }: IEventDrawView) => {
  return (
    <div
      className="event-draw-view"
      style={{ background: "red", top: el.top + "px", left: el.left + "px" }}
    >
      {/*<div>{name}</div>*/}
    </div>
  );
};
