import { BasePage } from "../../common/BasePage";
import { useParams } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { IFullEvent } from "../../../interfaces/IFullEvent";
import { storeContext } from "../../../store/root-store";
import { observer } from "mobx-react-lite";
import { serverUrl } from "../../../env";
import { QueryService } from "../../../services/QueryService";
import { StringHelper } from "../../../helpers/StringHelper";
import sanitizeHtml from "sanitize-html";

export const SingleEventPage = observer(() => {
  const { eventId } = useParams();
  const { singleStoreGL } = useContext(storeContext);

  const [fullEvent] = useState({} as IFullEvent);
  const loadEventInfo = async () => {
    if (eventId) {
      const fullEvent = await singleStoreGL.loadSingleEvent(+eventId);
      singleStoreGL.fullEvent.description = StringHelper.addTgLabel(
        singleStoreGL.fullEvent.description,
      );
      console.log(singleStoreGL.fullEvent.description);
    }
  };
  useEffect(() => {
    loadEventInfo();
  }, []);
  return (
    <BasePage>
      <div
        className="container"
        style={{ overflowY: "scroll", height: 100 + "%" }}
      >
        <div>
          <h3>{singleStoreGL.fullEvent.title}</h3>
        </div>
        <div style={{ width: 100 + "%", maxHeight: 100 + "%" }}>
          <img
            width={100 + "%"}
            height={100 + "%"}
            src={
              QueryService.getServerUri() +
              "/" +
              singleStoreGL.fullEvent.img_path
            }
            alt=""
          />
        </div>
        <div className="row">
          <div className="col-12">
            <p
              dangerouslySetInnerHTML={{
                __html: sanitizeHtml(singleStoreGL.fullEvent.description, {
                  allowedTags: ["b", "i", "em", "strong", "a"],
                  allowedAttributes: {
                    a: ["href"],
                  },
                }),
              }}
              style={{ whiteSpace: "pre-line" }}
            ></p>
          </div>
        </div>
      </div>
    </BasePage>
  );
});
